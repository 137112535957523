<template>
  <div class="attached-members">
    <p class="attached-members__desc" v-if="getOrderItem.myParticipationStatus === 'Approved' && getUser.role !== 'Moderator' && getUser.role !== 'Admin'">{{ participationStatusText }}</p>
    <div class="attached-members__content">
      <div class="attached-members__add-cards">
        <div v-if="getOrderParticipants && getOrderParticipants.length">
          <div v-if="!active" class="attached-members__cards">
            <attached-members-card @removeCard="removeItem" v-for="(item, key) in getOrderParticipants" :key="key" :data="item" :sendRequest="item.orderParticipation?.status === 'Assigned'" />
            <div class="show-more">
              <div @click="showAllParticipants" v-if="visibleLoadMoreParticipants" class="show-all-members">
                <img src="@/assets/svg/arrows/more.svg" alt="">
                <p>{{ $t('show-more') }}</p>
              </div>
              <div @click="closeParticipants" v-if="getOrderParticipants.length > 3" class="show-all-members">
                <img src="@/assets/svg/arrows/more.svg" alt="" style="transform: rotate(180deg)">
                <p>{{ $t('collapse') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="attached-members__add-cards-empty" v-else>
          <p>{{ $t('there-are-no-participants-attached') }}</p>
          <img src="@/assets/images/empty.png" alt="" />
        </div>
        <ui-button
            v-if="(!active && !showAddMember
            && getUser.role === 'Moderator' &&
            getOrderItem.status === 'Accepted'
            && getUser.id === getOrderItem.moderatedBy) ||
            (!active && !showAddMember && getUser.role === 'Admin' && getOrderItem.status === 'Accepted')"
            color="error"
            @click="showAddMember = true; showRequests = false;"
        >
          {{ $t('add-participants') }}
        </ui-button>
        <div v-show="showAddMember" class="attached-members__add-members">
          <h2 class="attached-members__add-members title" @click="showAddMember = false">{{ $t('add-participants') }}</h2>
          <div class="attached-members__wrap">
            <ui-input @input="changeVal" search v-model="addMembersSearch" :placeholder="$t('search')" />
            <div v-if="getProposedParticipants && getProposedParticipants.length" class="attached-members__cards members">
              <attached-members-card active v-for="(item, key) in getProposedParticipants" :key="key" :data="item" />
            </div>
            <div class="empty" v-else>
              {{ $t('empty') }}
            </div>
            <div @click="showAllProposedParticipants" v-if="visibleLoadMoreProposedParticipants" class="show-all-members">
              <img src="@/assets/svg/arrows/more.svg" alt="">
              <p>{{ $t('show-more-participants') }}</p>
            </div>
            <UiLoader v-model="loader" />
            <ui-button
                :disabled="!selectedParticipationRequests"
                @click="participantsCreate"
                color="error"
                class="add-members-action"
            >{{ $t('add') }} ({{ selectedParticipationRequests }})</ui-button>
          </div>
        </div>
      </div>
      <div
          class="attached-members__requests-cards proposed"
          v-if="getOrderItem.status !== 'Closed' &&
          getParticipationRequests.length > 0 ||
          (getOrderItem.status !== 'Closed' &&
          getParticipationRequests.length === 0 && search) ||
          isSearch"
      >
        <p
            v-if="(getUser.role === 'Moderator' ||
            getUser.role === 'Admin') &&
            getOrderItem.status === 'Accepted'"
            class="attached-members__cards-requests"
        >{{ $t('requests') }} ({{ getParticipationRequests ? getParticipationRequests.length : 0 }})</p>
        <ui-input search v-model="search" @input="searchRequests" :placeholder="$t('search')" />
        <div v-if="getParticipationRequests && getParticipationRequests.length" class="attached-members__requests-cards-items">
          <attached-members-card isRequest active v-for="(item, key) in getParticipationRequests" :key="key" :data="item" @denyParticipant="denyParticipant($event)" />
        </div>
        <div class="empty" v-else>
          {{ $t('empty') }}
        </div>
        <div class="attached-members__requests-cards-action" v-if="getParticipationRequests.length > 0">
<!--          <ui-button :disabled="!selectedProposeItem" @click="denyParticipant"  color="error">Отклонить ({{ selectedProposeItem }})</ui-button>-->
          <ui-button :disabled="!selectedProposeItem" @click="approveParticipant">{{ $t('accept') }} ({{ selectedProposeItem }})</ui-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: "AttachedMembers",
  components: {
    AttachedMembersCard: () => import('@/components/template/work-requests/ViewRequests/AttachedMembersCard'),
    UiInput: () => import('@/components/ui/UiInput'),
    UiLoader: () => import('@/components/ui/UiLoader'),
    UiButton: () => import('@/components/ui/UiButton'),

  },

  data() {
    return {
      count: 3,
      active: false,
      showRequests: false,
      loader: true,
      visibleLoadMoreParticipants: false,
      visibleLoadMoreProposedParticipants: false,
      showAllResult: true,
      search: '',
      addMembersSearch: '',
      showAddMember: false,
      isSearch: false
    }
  },

  async mounted () {
    this.callApi()
  },

  destroyed() {
    this.setProposedParticipants(null)
  },

  computed: {
    ...mapGetters(['getOrderItem','getUser', 'getOrderParticipants', 'getProposedParticipants', 'getParticipationRequests']),

    selectedProposeItem() {
      const selectedItems = this.getParticipationRequests.filter((val) => val.checked)
      return selectedItems ? selectedItems.length : 0
    },
    selectedParticipationRequests() {
      const selectedItems = this.getProposedParticipants?.filter((val) => val.checked)
      return selectedItems ? selectedItems.length : 0
    },
    participationStatusText() {
     return  (
         this.getOrderItem.myParticipationStatus === 'Approved' ? this.$t('you-are-participating-in-this-request') :
         this.getOrderItem.myParticipationStatus === 'Request' ? this.$t('enquiry-to-participate-in-this-request'):
         this.getOrderItem.myParticipationStatus === 'Denied' ? this.$t('enquiry-to-participate-in-request-has-been-denied') : ''
      )
    }
  },

  methods: {
    ...mapActions(['sendOrderParticipants', 'sendOrderProposedParticipantsMore', 'sendOrderProposedParticipants', 'sendParticipantsApprove', 'sendParticipantsDeny', 'deleteOrderParticipants', 'sendOrderParticipantsMore', 'sendParticipationRequests', 'createParticipants']),
    ...mapMutations(['setProposedParticipants']),
    callApi() {
      this.loader = true
      this.sendOrderParticipants({id: this.getOrderItem.id, skip: 0, take: 3, status: ['Assigned', 'Approved']}).then((res) => {
        this.visibleLoadMoreParticipants = res && res.length === 3
      })

      this.sendParticipationRequests({id: this.getOrderItem.id})
      this.sendOrderProposedParticipants({id: this.getOrderItem.id, skip: 0, take: 3, status: ['Request', 'Denied', 'Refused'] }).then((res) => {
        this.visibleLoadMoreProposedParticipants = res && res.length === 3
      }).finally(() => {
        this.loader = false
      })
    },
    changeVal() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.loader = true
        this.sendOrderProposedParticipants({id: this.getOrderItem.id, search: this.addMembersSearch, skip: 0, take: 3, status: ['Request', 'Denied', 'Refused'] }).then((res) => {
          this.visibleLoadMoreParticipants =  res && res.length === 3
          this.loader = false
        })
      }, 500)
    },
    searchRequests() {
      this.isSearch = true
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.loader = true
        this.sendParticipationRequests({id: this.getOrderItem.id, search: this.search}).then(() => {
          this.loader = false
          this.isSearch = false
        })
      }, 500)
    },
    removeItem(e) {
      this.deleteOrderParticipants({id: this.getOrderItem.id, userId: e.id}).then(() =>{
        this.loader = true
        this.sendOrderProposedParticipants({id: this.getOrderItem.id, status: ['Request', 'Denied', 'Refused']})
        this.sendOrderParticipants({id: this.getOrderItem.id, skip: 0, take: 3, status: ['Approved', 'Assigned']}).then((res) => {
          this.visibleLoadMoreParticipants =  res && res.length === 3
          this.loader = false
        })
      })
    },
    showAllParticipants() {
      this.loader = true
      this.sendOrderParticipantsMore({id: this.getOrderItem.id, skip: this.getOrderParticipants.length, take: 5, status: ['Approved', 'Assigned']}).then((res) => {
        this.visibleLoadMoreParticipants =  res && res.length === 5
      })
      .finally(() => {
        this.loader = false
      })
    },
    closeParticipants () {
      this.sendOrderParticipants({id: this.getOrderItem.id, skip: 0, take: 3, status: ['Approved', 'Assigned']}).then((res) => {
        this.visibleLoadMoreParticipants = res && res.length === 3
      })
    },
    showAllProposedParticipants() {
      this.loader = true
      this.sendOrderProposedParticipantsMore({id: this.getOrderItem.id, skip: this.getProposedParticipants.length, take: 5, status: ['Request', 'Assigned', 'Denied', 'Refused']}).then((res) => {
        this.visibleLoadMoreProposedParticipants = res && res.length === 5
      })
      .finally(() => {
        const el = document.querySelector('.members')
        if (el) {
          el.scroll({ top: el.scrollHeight, behavior: 'smooth' })
        }
        this.loader = false
      })
    },
    participantsCreate() {
      const ids = []
      this.getProposedParticipants.forEach((val) => {
        if (val.checked) {
          ids.push(val.id)
        }
      })
      const data = {
        id: this.getOrderItem.id,
        ids
      }
      this.createParticipants(data).then(() => {
        this.showAddMember = false
        this.callApi()
      })
    },
    // requestShow() {
    //   this.showRequests = !this.showRequests
    //   // this.showAddMember = false
    //   // if (!this.showRequests) {
    //   //   let arr = JSON.parse(JSON.stringify(this.getProposedParticipants))
    //   //   arr = this.getProposedParticipants.map((val) => {
    //   //     val.checked = false
    //   //     return val
    //   //   })
    //   //   this.setProposedParticipants(arr)
    //   // }
    // },
    denyParticipant(data) {
      this.sendParticipantsDeny(data).then(() => {
        this.callApi()
      })
    },
    approveParticipant() {
      const ids = []
      this.getParticipationRequests.forEach((val) => {
        if (val.checked) {
          ids.push(val.id)
        }
      })
      const data = {
        id: this.getOrderItem.id,
        ids
      }
      this.sendParticipantsApprove(data).then(() => {
        this.showRequests = false
        this.showAddMember = false
        this.callApi()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.attached-members {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__desc {
    padding: 0 10px;
  }
  &__content {
    padding: 0 10px;
    display: flex;
    gap: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__wrap {
    display: flex;
    flex-direction: column;

    .attached-members__cards {
      margin-top: 20px;
      padding-bottom: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &-requests {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        color: #E21F1F;
        cursor: pointer;
      }
    }

    .show-all-members {
      padding-top: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      margin-bottom: 15px;

      p {
        font-weight: 400;
        font-size: 8px;
        line-height: 8px;
        color: #9A9A9A;
      }
    }
  }

  &__add-cards {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;
    width: 50%;
    padding: 30px;
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 20px;
    height: max-content;

    @media (max-width: 768px) {
      box-shadow: none;
      background: none;
      border: none;
      width: 100%;
      padding: 20px 0 30px;
    }

    .attached-members__cards {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .show-more {
      display: flex;
      align-items: center;
      gap: 10px;

      .show-all-members {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        p {
          font-weight: 400;
          font-size: 8px;
          line-height: 8px;
          color: #9A9A9A;
        }
      }
    }
  }

  &__requests-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
    padding: 30px;
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 20px;
    height: max-content;

    @media (max-width: 768px) {
      box-shadow: none;
      background: none;
      border: none;
      width: 100%;
      padding: 20px 0 30px;
    }

    &-items {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .show-all-members {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      p {
        font-weight: 400;
        font-size: 8px;
        line-height: 8px;
        color: #9A9A9A;
      }
    }

    &-action {
      display: flex;
      gap: 10px;
    }
  }
  &__requests-cards .proposed {
    max-height: 100% !important;
  }

  &__add-members {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 10px;

    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #343432;
      cursor: pointer;
    }

    .add-members-action {
      margin-top: 10px;
    }
  }
  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .members {
    overflow: hidden;
    max-height: 240px;
    overflow-y: auto;
  }
}
.attached-members__add-cards-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 77px;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    color: #1B1A1F;
  }

  img {
    width: 262px;
    height: 262px;
  }
}
</style>
