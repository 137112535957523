<template>
  <div class="attached-files">
    <div class="attached-files__upload">
      <ui-loader v-model="loader" />
      <template v-if="documentFiles && documentFiles.length" >
        <a
            v-for="(item, key) in documentFiles.slice(0, count)"
            :key="key"
            :href="item.fileSrc"
            target="_blank"
            style="display: flex; align-items: center; gap: 10px;"
        >
          <img

              src="@/assets/svg/icon-docs-grey.svg"
              alt=""
          />
          <div class="attached-files__close" v-if="item.filename && getUser?.role === 'Moderator'" @click="deleteFile($event, {file: item.file, filename: item.filename})">
            <img
                src="@/assets/svg/close/white.svg"
                alt=""
            />
          </div>
          <div class="attached-files__info">
            <p>{{ item.name || item.originalname }}</p>
            <span>{{ getFullDate(item.createdAt || new Date())}}</span>
          </div>
        </a>
      </template>
      <div class="empty" v-else>
        {{ $t('empty') }}
      </div>
    </div>
    <div class="attached-files__more">
      <div @click="showAllFiles" v-if="documentFiles.length > 4 && documentFiles.length !== count" class="show-all-files">
        <img src="@/assets/svg/arrows/more.svg" alt="">
        <p>{{ $t('show-all-files') }}</p>
      </div>
      <div @click="showAllFiles" v-if="documentFiles.length === count && documentFiles.length > 4" class="show-all-files">
        <img src="@/assets/svg/arrows/more.svg" alt="" style="transform: rotate(180deg)">
        <p>{{ $t('collapse') }}</p>
      </div>
    </div>
    <label class="attached-files__plus" v-if="showAddFiles">
      <input @input="uploadDocument" type="file" ref="file" accept="image/*" >
      <span class="attached-files__btn">
          <img src="@/assets/svg/icon-plus-white.svg" alt="" />
        </span>
      <p class="attached-files__text">{{ $t('add-document') }}</p>
    </label>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {getFullDate} from "@/utils/general";

export default {
  name: "AttachedFiles",
  components: {
    UiLoader: () => import('@/components/ui/UiLoader')

  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      documentFiles: [],
      loader: false,
      getFullDate,
      count: 4
    }
  },

  computed: {
    ...mapGetters(['getOrderItem', 'getUser']),

    showAddFiles () {
      return (this.getUser.id === this.getOrderItem.createdBy.id && this.getOrderItem.status === 'Accepted') ||
            (!this.getOrderItem.isNew && (this.getUser.id === this.getOrderItem.moderatedBy || this.getOrderItem.myParticipationStatus === 'Approved'))
    }
  },

  mounted() {
    this.documentFiles = this.data.filesSrc
  },

  methods: {
    ...mapActions(['reqUpload', 'patchOrderId']),

    uploadDocument({ target }) {
      this.loader = true
      this.reqUpload({
        file: target.files[0],
        type: "document",
      }).then((res) => {
        this.$refs.file.value = null
        console.log(res,111);
        res.data.fileSrc = res.data.url
        this.documentFiles.push(res.data)
        const files = this.getOrderItem.files || []
        files.push(res.data.filename)

        const data = {
          id: this.getOrderItem.id,
          files
        }
        this.patchOrderId(data)
        this.showAllFiles()
      }).finally(() => {
          this.loader = false
      })
    },

    showAllFiles () {
      this.documentFiles.length !== this.count ? this.count = this.documentFiles.length : this.count = 4
    },

    deleteFile(e, data) {
      e.preventDefault();
      if(data.file) {
        this.documentFiles = this.documentFiles.filter((elem) => elem.file !== data.file);
      }else if(data.filename) {
        this.documentFiles = this.documentFiles.filter((elem) => elem.filename !== data.filename);
      }

      const files = []
      this.documentFiles.forEach(elem => files.push(elem.file || elem.filename))

      const payload = {
        id: this.getOrderItem.id,
        files
      }

      this.patchOrderId(payload)
      this.showAllFiles()
    },
  }
}
</script>

<style lang="scss" scoped>
.attached-files {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 20px 30px 20px;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  border-radius: 20px;

  @media (max-width: 768px) {
    padding: 0 12px 30px 12px;
    box-shadow: none;
    background: none;
    border: none;
  }

  &__more {
    display: flex;
    align-items: center;
    gap: 10px;

    .show-all-files {
      padding-top: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      margin-bottom: 15px;

      p {
        font-weight: 400;
        font-size: 8px;
        line-height: 8px;
        color: #9A9A9A;
      }
    }
  }

  a {
    @media(max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 114px;
      width: 100%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #1B1A1F;
      word-break: break-all;

      @media (max-width: 768px) {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #1B1A1F;
      }
    }

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #BFBDBD;

      @media (max-width: 768px) {
        font-weight: 400;
        font-size: 8px;
        line-height: 112.7%;
        color: #BFBDBD;
        width: 42px;
        text-align: center;
      }
    }
  }

  &__plus {
    display: flex;
    align-items: center;
    gap: 10px;

    input {
      display: none;
    }

    .attached-files__btn {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #E21F1F;
      color: #fff;
      border-radius: 50%;
      cursor: pointer;
    }

    .attached-files__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #E21F1F;
    }
  }

  &__upload {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media (max-width: 1080px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }

    a {
      position: relative;
    }

    &-plus {
      display: none;
      align-items: center;
      gap: 10px;
      width: 100%;

      @media (max-width: 768px){
        display: flex;
      }
      input {
        display: none;
      }

      .attached-files__btn {
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #E21F1F;
        color: #fff;
        border-radius: 50%;
        cursor: pointer;
      }

      .attached-files__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        color: #E21F1F;
      }
    }

    .attached-files__close {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 45px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #E21F1F;

      img {
        width: 14px;
        height: 14px;
      }
    }
  }

  &__items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }
}
.empty {
  padding: 0 !important;
  width: 100%;
}
</style>
