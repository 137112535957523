<template>
  <div class="view-requests-comment comment">
    <div v-if="getOrderComments && getOrderComments.length" class="comment__body">
      <div class="comment__body-items">
        <comment-item v-for="(item, key) in getOrderComments" :key="key" :data="item" />
      </div>
      <div class="comment__body-footer" v-if="visibleLoadMoreComments || getOrderComments.length > 3">
        <div
            v-if="visibleLoadMoreComments"
            class="comment__body-footer-more"
            @click="showMoreComments"
        >
          <img src="@/assets/svg/arrows/more.svg" alt="">
          <p>{{ $t('show-more') }}</p>
        </div>
        <div
            v-if="getOrderComments.length > 3"
            @click="closeComments"
            class="comment__body-footer-more">
          <img src="@/assets/svg/arrows/more.svg" alt="" style="transform: rotate(180deg)">
          <p>{{ $t('collapse') }}</p>
        </div>
      </div>
    </div>
    <div class="empty" v-else>
      {{ $t('empty') }}
    </div>
    <ui-textarea
        v-if="
          getOrderItem.status === 'Accepted' &&
          getUser.id === getOrderItem.moderatedBy ||
          getOrderItem.myParticipationStatus === 'Approved' ||
          (getUser.role === 'Active' && getOrderItem.status !== 'Closed')"
        v-model="commentText"
        show-send
        min-height="40"
        :placeholder="$t('add-comment')"
        @send="sendComment"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: "Comment",
  components: {
    UiTextarea: () => import('@/components/ui/UiTextarea'),
    CommentItem: () => import('@/components/template/work-requests/ViewRequests/CommentItem'),
  },

  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      visibleLoadMoreComments: false,
      commentText: '',
      countMore: 1,
    }
  },

  mounted() {
    this.sendOrderComments({id: this.data.id, take: 3}).then((res) => {
        this.visibleLoadMoreComments = res && res.length === 3
    })
  },

  methods: {
    ...mapActions(['sendOrderComments', 'sendOrderCommentsMore', 'postOrderComment']),
    sendComment() {
      const parentCommentId = this.getOrderComments && this.getOrderComments.length ?
          this.getOrderComments[0].parentCommentId: null
      const data = {
        id: this.data.id,
        message: this.commentText,
        parentCommentId,
        skip: this.getOrderComments.length ,
        take: 5
      }

      if (this.commentText) {
        this.postOrderComment(data)
        this.commentText = ''
      }
    },

    showMoreComments() {
      this.countMore++
      const data = {
        id: this.data.id,
        skip: this.getOrderComments.length ,
        take: 5
      }
      this.sendOrderCommentsMore(data).then((res) => {
        this.visibleLoadMoreComments = res.length === 5
      })
    },
    closeComments () {
      this.sendOrderComments({id: this.data.id, take: 3}).then((res) => {
        if (res.length === 3) {
          this.visibleLoadMoreComments = true
        }
      })
    }
  },

  computed: {
    ...mapGetters(['getUser', 'getOrderComments', 'getOrderItem'])
  }
}
</script>

<style lang="scss" scoped>
.comment {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 20px 20px 30px;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  border-radius: 20px;

  @media (max-width: 768px) {
    padding: 0 12px 30px 12px;
    box-shadow: none;
    background: none;
    border: none;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-items {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &-footer {
      display: flex;
      gap: 10px;
      align-items: center;
      border-top: 1px solid #F5F5F5;
      padding-top: 20px;

      &-more {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        p {
          font-weight: 400;
          font-size: 8px;
          line-height: 8px;
          color: #9A9A9A;
        }
      }
    }
  }
  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
//:deep(.comment-item:not(:last-child)) {
//  border-bottom: 1px solid #F5F5F5;
//  padding-bottom: 20px;
//}
</style>
