<template>
  <div v-if="data" class="location">
    <div class="location__map">
      <GmapMap
          :center="center"
          :zoom="10"
          map-type-id="terrain"
          style="height: 194px"
          :options="createMapOptions"
      >
        <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
        />
      </GmapMap>
    </div>
    <div class="location__info">
      <div class="location__info-item width">
        {{ $t('latitude') }}<span>{{ data.lat }}</span>
      </div>
      <div class="location__info-item length">
        {{ $t('longitude') }}<span>{{ data.lng }}</span>
      </div>
    </div>
    <p v-if="data.district" class="location__city">{{ data.district.name }}</p>
  </div>
</template>

<script>
export default {
  name: "Location",
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      createMapOptions: {
        fullscreenControl: false,
      }
    }
  },

  computed: {
    markers() {
      return [
        {
          position: {
            lat: Number(this.data.lat),
            lng: Number(this.data.lng),
          },
        }
      ]
    },
    center() {
      return {
        lat: Number(this.data.lat),
        lng: Number(this.data.lng),
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.location {
  padding: 20px 20px 35px 20px;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  border-radius: 20px;

  &__map {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    padding: 0 12px 30px 12px;
    box-shadow: none;
    background: none;
    border: none;
  }

  &__map {
    width: 100%;
    height: 194px;
    border: 1px solid #F5F5F5;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  &__info {
    display: flex;
    gap: 30px;

    @media (max-width: 768px) {
      justify-content: space-between;
      gap: 4px;
    }
    &-item {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #343432;
      margin-bottom: 10px;

      span {
        color: #CE2121;
      }
    }
  }

  &__city {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #343432;
  }

  :deep(.gmnoprint div) {
    display: none !important;
  }
}
</style>
