<template>
  <div v-if="getOrderItem && getOrderItem.id" class="side-bar">
    <div class="side-bar__nav">
      <accordion
          v-model="activeAccordion"
          v-for="(item,key) in data"
          @click="clickToLink(item)"
          :item="item"
          :key="key"
          outsideMenu
          :disabled="
            (item.ref === 'member' && getUser.role === 'Active' && getOrderItem.status === 'Moderation') ||
            disabled && item.ref !== 'location' && item.ref !== 'member' && item.ref !== 'file'"
      >
        <template #body>
          <component :is="item.component" :data="getOrderItem" />
        </template>
      </accordion>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { getStatus, getFullDate } from '@/utils/general'
import { getUserAvatar } from "@/utils/user";

import Accordion from "@/components/template/work-requests/ViewRequests/Accordion";
import ViewRequestsSideBar from "@/components/template/work-requests/ViewRequests/ViewRequestsSideBar";
import Location from "@/components/template/work-requests/ViewRequests/Location";
import AttachedMembers from "@/components/template/work-requests/ViewRequests/AttachedMembers";
import AttachedFiles from "@/components/template/work-requests/ViewRequests/AttachedFiles";
import Comment from "@/components/template/work-requests/ViewRequests/Comment";
import RelatedApplications from "@/components/template/work-requests/ViewRequests/RelatedApplications.vue";
export default {
  components: {
    RelatedApplications,
    UiButton: () => import('@/components/ui/UiButton'),
    UiAlert: () => import('@/components/ui/UiAlert'),
    UiTextarea: () => import('@/components/ui/UiTextarea'),
    Accordion,
    ViewRequestsSideBar,
    Location,
    AttachedMembers,
    AttachedFiles,
    Comment,

  },
  data() {
    return {
      getStatus,
      getUserAvatar,
      getFullDate,
      activeAccordion: {},
      selectedReason: {},
      data: [
        { id: 2, text: this.$t('location-1'), icon: require('@/assets/svg/icon-location.svg'), component: Location, ref: 'location'},
        { id: 3, text: this.$t('attached-participants'), icon: require('@/assets/svg/icon-person-red.svg'), disabledIcon: require('@/assets/svg/icon-person-grey.svg'), component: AttachedMembers, ref: 'member',},
        { id: 4, text: this.$t('attached-files'), icon: require('@/assets/svg/icon-file-red.svg'), disabledIcon: require('@/assets/svg/icon-file-grey.svg'), component: AttachedFiles, ref: 'file'},
        { id: 5, text: this.$t('comments'), icon: require('@/assets/svg/icon-comment-red.svg'), disabledIcon: require('@/assets/svg/icon-comment-grey-work-request.svg'), component: Comment, ref: 'comment'},
        // { id: 6, text: 'Связанные заявки (0)', icon: require('@/assets/svg/icon-comment-red.svg'), disabledIcon: require('@/assets/svg/icon-comment-grey-work-request.svg'), component: RelatedApplications, ref: 'relatedApplications'},
      ],
      idx: 0,
      role: 'moderator',
      showRejectAlert: false,
      isReject: false,
      isAccept: false,
      rejectDescription: ''
    }
  },
  computed: {
    ...mapGetters(['getOrderItem','getUser', 'getRejectionReasons']),
    getDate() {
      if (this.getOrderItem.createdAt)
        return this.getFullDate(this.getOrderItem.createdAt).split(',')[0]

      return null
    },
    getTime() {
      if (this.getOrderItem.createdAt)
        return this.getFullDate(this.getOrderItem.createdAt).split(',')[1]

      return null
    },
    statusParticipationInfo() {
      return this.getOrderItem.myParticipationStatus == null ? {type: 'default', buttonText: '', color: '#E21F1F'} :
          this.getOrderItem.myParticipationStatus === 'Request' ? {type: 'pending', buttonText: this.$t('request-sent'), color: '#e77f00'} :
              this.getOrderItem.myParticipationStatus === 'Approved' ? { type: 'approved', buttonText: '', color: '' } :
                  this.getOrderItem.myParticipationStatus === 'Denied' ?  {type: 'denied', buttonText: this.$t('enquiry-to-participate-in-request-has-been-denied'), color: '#810202'} : null
    },
    disabled() {
      return this.getUser.role !== "Admin" &&
          this.getUser.role !== "Moderator" &&
          (this.getOrderItem.myParticipationStatus !== "Approved" ||
          this.getOrderItem.isNew) &&
          (this.getOrderItem.createdBy.id !== this.getUser.id || (this.getUser.role === 'Active' && this.getOrderItem.status === 'Moderation'))
    }
  },
  watch: {
    getUser: {
      immediate: true,
      handler() {
        this.data = this.data.map((val, key) => {
          if (key > 2 && this.getUser.role !== 'Admin' && this.getUser.role !== 'Moderator' && this.statusParticipationInfo.type !== 'approved' && (this.getOrderItem.status === 'Moderation' || this.getOrderItem.status === 'Rejected')) {
            val.disabled = true
            if (key === 5 && this.getOrderItem.status === 'Rejected') {
              val.disabled = false
            }
          }

          return val
        })
        this.sendRejectionReasons().then((res) => {
          this.selectedReason = res[0]
        })
      }
    },
    showRejectAlert() {
      if(this.showRejectAlert) {
        document.querySelector('.header').style.position = 'static'
      }
      else {
        document.querySelector('.header').style.position = 'fixed'
      }
    }
  },
  methods: {
    ...mapActions(['sendOrderId', 'createParticipationRequests', 'sendRejectionReasons', 'sendOrderLike', 'sendOrderDisLike', 'sendOrderAccept', 'sendOrderReject', 'sendOrderClose']),
    clickToLink(item) {
      this.$emit('scrollTo', item.ref)
    },
    likeOrder() {
      if (this.getOrderItem.isLikedByMe) {
        this.sendOrderDisLike(this.getOrderItem.id)
      } else {
        this.sendOrderLike(this.getOrderItem.id)
      }
    },
    orderAccept() {
      this.sendOrderAccept(this.getOrderItem.id).then(() => {
        this.isAccept = true
      })
    },
    requestAttach() {
      this.createParticipationRequests({ id: this.getOrderItem.id }).then(() => {
        const { id } = this.$route.params
        this.sendOrderId(id)
      })
    },
    orderReject() {
      const data = {
        id: this.getOrderItem.id,
        rejectDescription: this.rejectDescription,
        rejectReasonId: this.selectedReason.id
      }
      this.sendOrderReject(data).then(() => {
        this.showRejectAlert = false
        this.isReject = true
      })
    },
    selectReason(item) {
      this.selectedReason = item
    }
  },
}
</script>

<style lang="scss" scoped>
.side-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  //background-color: #FFFFFF;
  border-radius: 0 0 20px 20px;
  padding-bottom: 30px;

  &__nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;

    &-link {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 20px 20px 20px 40px;
      border-top: 1px solid #F5F5F5;

      .link-left {
        display: flex;
        align-items: center;
        gap: 10px;

        &__text {
          font-weight: 400;
          font-size: 16px;
          line-height: 15px;
          color: #343432;
        }
      }

      .rotate {
        transform: rotate(-90deg);
        transition: .3s;
      }
    }
  }
  &__footer {
    display: flex;
    gap: 10px;
    padding: 0 30px;
    min-height: 40px;

    &_chip {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      padding: 13px 0 14px;
      border-radius: 200px;
      font-weight: 400;
      font-size: 14px;
      line-height: 13px;
      color: #FFFFFF;
    }

    &_wrap {
      width: 100%;
    }

    &_item {
      display: flex;
      gap: 10px;
    }

    @media (max-width: 768px) {
      padding: 0 12px;
    }
  }
  ::v-deep .ui-alert {
    padding: 33px 20px 36px 20px;
    align-items: start;
  }
}

.reject-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #343432;
}

.reject-body {
  min-width: 366px;
  width: 100%;
  &__questions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  ::v-deep .ui-textarea {
    margin-bottom: 30px;
  }

  &__line {
    border-bottom: 1px solid #F1F1F3;
    margin-bottom: 20px;
  }
}
.chip {
  width: 10px;
  height: 10px;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  border-radius: 200px;
}
</style>
