var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"attached-members"},[(_vm.getOrderItem.myParticipationStatus === 'Approved' && _vm.getUser.role !== 'Moderator' && _vm.getUser.role !== 'Admin')?_c('p',{staticClass:"attached-members__desc"},[_vm._v(_vm._s(_vm.participationStatusText))]):_vm._e(),_c('div',{staticClass:"attached-members__content"},[_c('div',{staticClass:"attached-members__add-cards"},[(_vm.getOrderParticipants && _vm.getOrderParticipants.length)?_c('div',[(!_vm.active)?_c('div',{staticClass:"attached-members__cards"},[_vm._l((_vm.getOrderParticipants),function(item,key){return _c('attached-members-card',{key:key,attrs:{"data":item,"sendRequest":item.orderParticipation?.status === 'Assigned'},on:{"removeCard":_vm.removeItem}})}),_c('div',{staticClass:"show-more"},[(_vm.visibleLoadMoreParticipants)?_c('div',{staticClass:"show-all-members",on:{"click":_vm.showAllParticipants}},[_c('img',{attrs:{"src":require("@/assets/svg/arrows/more.svg"),"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.$t('show-more')))])]):_vm._e(),(_vm.getOrderParticipants.length > 3)?_c('div',{staticClass:"show-all-members",on:{"click":_vm.closeParticipants}},[_c('img',{staticStyle:{"transform":"rotate(180deg)"},attrs:{"src":require("@/assets/svg/arrows/more.svg"),"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.$t('collapse')))])]):_vm._e()])],2):_vm._e()]):_c('div',{staticClass:"attached-members__add-cards-empty"},[_c('p',[_vm._v(_vm._s(_vm.$t('there-are-no-participants-attached')))]),_c('img',{attrs:{"src":require("@/assets/images/empty.png"),"alt":""}})]),((!_vm.active && !_vm.showAddMember
            && _vm.getUser.role === 'Moderator' &&
            _vm.getOrderItem.status === 'Accepted'
            && _vm.getUser.id === _vm.getOrderItem.moderatedBy) ||
            (!_vm.active && !_vm.showAddMember && _vm.getUser.role === 'Admin' && _vm.getOrderItem.status === 'Accepted'))?_c('ui-button',{attrs:{"color":"error"},on:{"click":function($event){_vm.showAddMember = true; _vm.showRequests = false;}}},[_vm._v(" "+_vm._s(_vm.$t('add-participants'))+" ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAddMember),expression:"showAddMember"}],staticClass:"attached-members__add-members"},[_c('h2',{staticClass:"attached-members__add-members title",on:{"click":function($event){_vm.showAddMember = false}}},[_vm._v(_vm._s(_vm.$t('add-participants')))]),_c('div',{staticClass:"attached-members__wrap"},[_c('ui-input',{attrs:{"search":"","placeholder":_vm.$t('search')},on:{"input":_vm.changeVal},model:{value:(_vm.addMembersSearch),callback:function ($$v) {_vm.addMembersSearch=$$v},expression:"addMembersSearch"}}),(_vm.getProposedParticipants && _vm.getProposedParticipants.length)?_c('div',{staticClass:"attached-members__cards members"},_vm._l((_vm.getProposedParticipants),function(item,key){return _c('attached-members-card',{key:key,attrs:{"active":"","data":item}})}),1):_c('div',{staticClass:"empty"},[_vm._v(" "+_vm._s(_vm.$t('empty'))+" ")]),(_vm.visibleLoadMoreProposedParticipants)?_c('div',{staticClass:"show-all-members",on:{"click":_vm.showAllProposedParticipants}},[_c('img',{attrs:{"src":require("@/assets/svg/arrows/more.svg"),"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.$t('show-more-participants')))])]):_vm._e(),_c('UiLoader',{model:{value:(_vm.loader),callback:function ($$v) {_vm.loader=$$v},expression:"loader"}}),_c('ui-button',{staticClass:"add-members-action",attrs:{"disabled":!_vm.selectedParticipationRequests,"color":"error"},on:{"click":_vm.participantsCreate}},[_vm._v(_vm._s(_vm.$t('add'))+" ("+_vm._s(_vm.selectedParticipationRequests)+")")])],1)])],1),(_vm.getOrderItem.status !== 'Closed' &&
          _vm.getParticipationRequests.length > 0 ||
          (_vm.getOrderItem.status !== 'Closed' &&
          _vm.getParticipationRequests.length === 0 && _vm.search) ||
          _vm.isSearch)?_c('div',{staticClass:"attached-members__requests-cards proposed"},[((_vm.getUser.role === 'Moderator' ||
            _vm.getUser.role === 'Admin') &&
            _vm.getOrderItem.status === 'Accepted')?_c('p',{staticClass:"attached-members__cards-requests"},[_vm._v(_vm._s(_vm.$t('requests'))+" ("+_vm._s(_vm.getParticipationRequests ? _vm.getParticipationRequests.length : 0)+")")]):_vm._e(),_c('ui-input',{attrs:{"search":"","placeholder":_vm.$t('search')},on:{"input":_vm.searchRequests},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.getParticipationRequests && _vm.getParticipationRequests.length)?_c('div',{staticClass:"attached-members__requests-cards-items"},_vm._l((_vm.getParticipationRequests),function(item,key){return _c('attached-members-card',{key:key,attrs:{"isRequest":"","active":"","data":item},on:{"denyParticipant":function($event){return _vm.denyParticipant($event)}}})}),1):_c('div',{staticClass:"empty"},[_vm._v(" "+_vm._s(_vm.$t('empty'))+" ")]),(_vm.getParticipationRequests.length > 0)?_c('div',{staticClass:"attached-members__requests-cards-action"},[_c('ui-button',{attrs:{"disabled":!_vm.selectedProposeItem},on:{"click":_vm.approveParticipant}},[_vm._v(_vm._s(_vm.$t('accept'))+" ("+_vm._s(_vm.selectedProposeItem)+")")])],1):_vm._e()],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }