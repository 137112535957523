<template>
  <div class="related-applications">
    <ui-button color="error" @click="$router.push({ path: '/work-requests', query: {category: 'related-applications'} })">{{ $t('attach-request') }}</ui-button>
    <div class="related-applications__main">

    </div>
  </div>
</template>

<script>
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: 'RelatedApplications',
  components: {UiButton},
}
</script>

<style lang="scss" scoped>
.related-applications {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__main {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }
}
::v-deep .button {
  max-width: 660px;
}
</style>